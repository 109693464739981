import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#3CD5AF';
const footerRelatedLinks = [
	{
		title: 'Ackermann typographie',
		url: '/fr/projets/ackermann-typographie/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckBrandPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-cover.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-brandDNA-FR.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-logo-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-logo-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-duo-christophe-texture.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-duo-farine-texture.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-duo-flute-texture.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-patisserie.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-packaging-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-packaging-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-packaging-big.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-pain.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_12: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-chocololat.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_13: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-papier-soie.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_14: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-team-duo.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_15: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-team-grille.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_16: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-duo-patisserie-pain.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_17: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-smart.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_18: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-sac.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_19: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-carte-visite.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_20: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-affiche-typo-01.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_21: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-brand-affiche-typo-02.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_22: file(
					relativePath: {
						eq: "projects/ackermann/branding/img-link-typo-FR.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/ackermann-branding/',
					lang: 'en',
				},
			]}
			title="Ackermann Branding - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Ackermann"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Projet">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Transformer de la matière brute en une pièce
							artistique et gustative, tel est le métier
							d’Ackermann.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Positionnement de marque',
								'Branding',
								'Identité visuelle',
								'Packaging',
								'Typographie',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							Artisan boulanger, pâtissier et chocolatier suisse
							installé dans la région d’Yverdon-les-Bains reconnu
							par des prix locaux et internationaux, Christophe
							Ackermann représente la troisième génération de
							boulangers. Après dix ans de développement, il était
							temps pour lui d’ouvrir un nouveau chapitre avec la
							volonté de refondre sa marque.
						</p>

						<h3>Notre réponse</h3>
						<p>
							L’art boulanger est ancestral; il s’exprime dans
							l’artisanat, le travail des formes et de la matière.
							Nous avons identifié les marqueurs qui font
							d’Ackermann une boulangerie singulière. En nous
							projetant dans son avenir, nous avons travaillé à le
							formaliser visuellement.
						</p>

						<h3>Pour approfondir</h3>
						<ButtonSecondary
							href="/fr/projets/ackermann-typographie/"
							text="Typographie sur mesure Ackermann"
							data-target
						/>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>ADN de la marque</h3>
							<p>
								L’art, l’artisanat traditionnel et l’humain sont
								trois vecteurs forts de l’identité d’Ackermann,
								que la marque doit retranscrire.
							</p>
						</div>
						<Image
							{...data.image_1.childImageSharp}
							alt="ADN de la marque"
						/>
					</div>
				</div>

				<Column modifiers="left">
					<div className="column__media text-content">
						<Image {...data.image_2.childImageSharp} alt="Logo" />
						<Image {...data.image_3.childImageSharp} alt="Logo" />
						<Video src="e7a1e2bc6fa54352f5b909beeecf43b0" alt="" />
					</div>

					<div className="column__text column__sticky text-content">
						<h3>À la fois traditionnelle et progressiste</h3>
						<p>
							La première expression du repositionnement se
							manifeste dans le dessin de lettrage du logo
							Ackermann cherchant à en créer un symbole distinctif
							et représentatif de l’ADN de la marque.
						</p>
						<p>
							Le dessin de lettrage du logo est contrasté avec une
							base de linéale contemporaine et le contraste des
							capitales romaines. Le logo est un parfait équilibre
							graphique entre style traditionnel et contemporain,
							entre tradition et progrès, jouant ainsi avec la
							tension de la marque. Il s’inscrit esthétiquement
							dans un style graphique raffiné.
						</p>
						<p>
							Les similarités entre le métier de l’artisan
							boulanger et celui de l’artisan typographe ont été
							source d’inspiration. Le respect de la tradition
							fortement ancrée dans ces deux métiers est associé à
							la volonté d’innover et de se réinventer.
						</p>
					</div>
				</Column>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>L'art de sublimer la matière</h3>
						<p>
							La nouvelle marque présente une vision artistique de
							l’utilisation des ingrédients du boulanger. Chaque
							forme est une métaphore artistique de la matière.
						</p>
						<p>
							Ainsi les farines, les chocolats et les pâtes ne
							sont plus de la matière première, mais deviennent de
							la matière artistique.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_4.childImageSharp}
							alt="Duo Christophe texture"
						/>
						<Image
							{...data.image_5.childImageSharp}
							alt="Duo farine texture"
							className="grid-spacing"
						/>
						<Image
							{...data.image_6.childImageSharp}
							alt="Duo flûte texture"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_7.childImageSharp}
					alt="Pâtisserie"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="L’identité d’Ackermann présente une palette presque infinie de variations."
					more="L’identité visuelle s’inspire des anciens emballages de produits traditionnels comme les savons ibères, qui se présentaient avec une diversité de visuels très créatifs tout en restant cohérents."
					cite=""
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full" modifiers="gutter">
					<Image {...data.image_8.childImageSharp} alt="Packaging" />
					<Image {...data.image_9.childImageSharp} alt="Packaging" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_10.childImageSharp}
					alt="Packaging"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_11.childImageSharp} alt="Pain" />
					<Image {...data.image_12.childImageSharp} alt="Chocolat" />
				</Gallery>

				<Image
					className="col-full grid-spacing"
					{...data.image_13.childImageSharp}
					alt="Papier soie"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="Ackermann, c’est de l’humain, de l’authenticité et de la passion. Derrière chaque produit, ce sont des collaborateurs et des artisans régionaux parfois peu visibles qui travaillent et s’engagent en faveur de la qualité et de la subtilité gustative."
					cite=""
					style={{ textAlign: 'center' }}
				/>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>Les images parlent mieux que mille mots</h3>
						<p>
							Le travail photographique a comme objectif de
							représenter au mieux l’authenticité, la simplicité
							et la chaleur humaine qui s’inscrit dans l’ADN de la
							boulangerie.
						</p>
						<p>
							La composition se veut épurée avec une approche
							minimaliste, permettant de laisser le sujet
							s’exprimer pleinement.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_14.childImageSharp}
							alt="Équipe duo"
						/>
						<Image
							{...data.image_15.childImageSharp}
							alt="Équipe grille"
							className="grid-spacing"
						/>
						<Image
							{...data.image_16.childImageSharp}
							alt="Duo pâtisserie pain"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Blockquote
					uptitle="Témoignage du client"
					quote="« J'ai trouvé à vos côtés un partenaire d'exception. Vous m'avez aidé à poser les fondations d'un tout nouveau chapitre, qui génère des changements positifs stratégiques. Le résultat est absolument génial et je suis très fier de faire équipe avec vous. »"
					cite="Christophe Ackermann"
				/>

				<Image
					className="col-full grid-spacing"
					{...data.image_17.childImageSharp}
					alt="Smart"
				/>

				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_18.childImageSharp} alt="Sac" />
					<Image
						{...data.image_19.childImageSharp}
						alt="Cartes de visite"
					/>
				</Gallery>

				<Gallery className="col-full" modifiers="border">
					<Image {...data.image_20.childImageSharp} alt="Affiche" />
					<Image {...data.image_21.childImageSharp} alt="Affiche" />
				</Gallery>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>
							Une typographie dessinée sur mesure pour Ackermann
						</h3>
						<p>
							Découvrez la typographie dessinée pour Ackermann;
							elle crée une force de distinction unique à la
							marque.
						</p>
						<ButtonSecondary
							href="/fr/projets/ackermann-typographie/"
							text="Typographie sur mesure Ackermann"
							data-target
						/>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_22.childImageSharp}
							alt="Typographie"
						/>
					</div>
				</Column>
			</section>
		</Layout>
	);
};

export default ProjectAckBrandPage;
